<template>
  <svg width="28" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 10h24M2 2h24M2 18h24"
      stroke="#54718E"
      stroke-width="2.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
